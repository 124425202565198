import React, {useCallback, useState} from 'react';
import EditDialog from "../components/EditDialog";
import {createStyles, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {postFile} from "../utils/ajax";
import {remoteRoutes} from "../data/constants";
import Grid from "@material-ui/core/Grid";
import {hasNoValue} from "./inputs/inputHelpers";
import Button from "@material-ui/core/Button";
import {DropzoneArea} from 'material-ui-dropzone'
import Toast from "../utils/Toast";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ImportError, XDocumentViewModel} from "../__generated__/apiTypes";

const fileTypes = [
    ".png",'.jpeg','.jpg','.JPG','.JPEG','.PNG'
]

interface IProps {
    show: boolean
    onClose: () => any
    onDone: (data:XDocumentViewModel) => any
}

export const UploadErrors: React.FC<{ errors: ImportError[] }> = ({errors}) => {
    return <Box style={{maxHeight:100}}>
        <ul>
            {errors.map((it: any) => (
                <li key={it.row}>
                    Row: {it.row} : {it.error}
                </li>
            ))}
        </ul>
    </Box>
}

const useStyles = makeStyles(() =>
    createStyles({
        zone: {
            width:"100%"
        },
    }),
);

const SignatureUpload = ({show, onClose, onDone}: IProps) => {
    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false)
    const [files, setFiles] = useState<any[] | null>(null)

    const onDrop = useCallback((files: any[]) => {
        if (hasNoValue(files)) {
            return
        }
        setFiles(files)
    }, [])


    function handleClose() {
        setFiles(null)
        setLoading(false)
        onClose()
    }


    const uploadFile = () => {
        if (!files || hasNoValue(files)) {
            Toast.warn("Please select an image")
            return
        }
        setLoading(true)
        let formData = new FormData()
        formData.append('file', files[0])
        postFile(remoteRoutes.bulkSignatureUpload, formData, data => {
            onDone(data)
        }, undefined, () => {
            setLoading(false)
        })
    }


    return (
        <EditDialog title='Upload signature' open={show} onClose={handleClose} disableBackdropClick maxWidth="md">

            <Grid container spacing={1} style={{minHeight: 250, paddingRight: 8}}>

                <Grid item xs={12}>
                    <Box p={3} display="flex" justifyContent='center'>
                        <DropzoneArea
                            dropzoneClass={classes.zone}
                            dropzoneText='Drop signature here or click'
                            acceptedFiles={fileTypes}
                            onChange={onDrop}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={3} pt={1}>
                        <Grid container spacing={1} alignContent='flex-end' justify='flex-end'>
                            {
                                loading &&
                                <Grid item>
                                    <CircularProgress/>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    type='button'
                                    variant='outlined'
                                    color='default'
                                    onClick={handleClose}
                                    disabled={loading}
                                >Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type='button'
                                    variant='outlined'
                                    color='primary'
                                    onClick={uploadFile}
                                    disabled={loading || hasNoValue(files)}
                                >Submit</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

        </EditDialog>
    );
}

export default SignatureUpload;

