import React, {useState} from 'react';
import {Button, useMediaQuery} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Form, Formik, FormikHelpers} from 'formik';
import * as yup from "yup";
import XTextInput from "../../components/inputs/XTextInput";
import {useRegisterStyles} from "./registerStyles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import XSelectInput from "../../components/inputs/XSelectInput";
import {toOptions} from "../../components/inputs/inputHelpers";
import {subIdCategories} from "../../data/comboCategories";
import {RegisterModel, XDocumentViewModel} from "../../__generated__/apiTypes";
import {post} from "../../utils/ajax";
import {
    APP_NAME,
    APP_NAME_SHORT,
    appLogo,
    localRoutes,
    passwordRegex,
    remoteRoutes,
    termsLink
} from "../../data/constants";
import {Alert} from "@material-ui/lab";
import {Link as RouterLink, Link} from "react-router-dom";
import {CheckOutlined} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useTheme from "@material-ui/core/styles/useTheme";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {themeColor} from "../../theme/custom-colors";
import SignatureUpload from "../../components/SignatureUpload";
import Toast from "../../utils/Toast";


const initialValues =
    {
        fullName: "",
        email: "",
        phone: "",
        otherPhone: "",
        password: "",
        confirmPassword: "",
        companyId: "",
        idCategory: "",
        idNumber: "",
        acceptTerms: false
    }

const schema = yup.object().shape(
    {
        fullName: yup.string()
            .min(3)
            .required("Full name is required"),
        email: yup.string()
            .email('Invalid email')
            .required("Email is required"),
        phone: yup.string()
            .required("Phone number is required"),
        password: yup.string()
            .matches(passwordRegex)
            .required("Password is required"),
        confirmPassword: yup.string()
            .matches(passwordRegex)
            .oneOf([yup.ref('password')], "Passwords must match")
            .required("Confirm password is required"),
        companyId: yup.string()
            .required("Company Name is required"),
        idCategory: yup.string()
            .required("ID type is required"),
        idNumber: yup.string()
            .required("ID number is required")

    }
);

function Register() {
    const classes = useRegisterStyles()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const [result, setResult] = useState<any | null>(null)
    const [agreement, setAgreement] = useState<boolean>(initialValues.acceptTerms)
    const [signature, setSignature] = useState<XDocumentViewModel|null>(null)
    const [signatureUpload, setSignatureUpload] = useState<boolean>(false)

    const onAgreementChanged = (e: any, acceptTermsAndConditions: boolean) => {
        setAgreement(acceptTermsAndConditions)
    }

    function handleStartSignature() {
        setSignatureUpload(true)
        setSignature(null)
    }

    function handleSignatureComplete(data:XDocumentViewModel) {
        setSignatureUpload(false)
        setSignature(data)
    }

    const onSubmit = (values: any, actions: FormikHelpers<any>) => {

        if (!signature) {
            actions.setSubmitting(false)
            Toast.warn("Please upload your signature")
            return
        }

        const model: RegisterModel = {
            fullName: values.fullName,
            email: values.email,
            phone: values.phone,
            otherPhone: values.otherPhone,
            password: values.password,
            confirmPassword: values.confirmPassword,
            companyId: values.companyId,
            idCategory: values.idCategory,
            idNumber: values.idNumber,
            acceptTerms: agreement,
            signatureId: signature.id!
        }

        post(remoteRoutes.register, model, (result) => {
                actions.setSubmitting(false)
                setResult(result)
            }, undefined,
            () => {
                actions.setSubmitting(false)
            })
    }

    let inputPaddingX = 3;
    if (isSmall) {
        inputPaddingX = 1;
    }

    if (result) {
        return (
            <div style={{backgroundPosition: "top center"}} className={classes.root}>
                <AppBar position="sticky" color='default'>
                    <Toolbar>
                        <Avatar alt="Logo" src={appLogo} className={classes.icon}/>
                        {
                            isSmall ?
                                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                                    <RouterLink to="/" style={{
                                        color: themeColor,
                                        textDecoration: 'none'
                                    }}>{APP_NAME_SHORT}</RouterLink>
                                </Typography>
                                :
                                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                                    <RouterLink to="/" style={{
                                        color: themeColor,
                                        textDecoration: 'none'
                                    }}>{APP_NAME}</RouterLink>
                                </Typography>
                        }
                    </Toolbar>
                </AppBar>
                <Box display='flex' justifyContent='center'>
                    <Box>
                        <Paper className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <CheckOutlined/>
                            </Avatar>
                            <Typography component="h1">
                                Account created successfully
                            </Typography>

                            <Box p={1}>
                                <Alert>{result.message}</Alert>
                                <Box display="flex" justifyContent='center' pt={2}>
                                    <Link to={localRoutes.login}>Login</Link>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </div>
        )
    }


    return (
        <div style={{backgroundPosition: "top center"}} className={classes.root}>
            <AppBar position="sticky" color='default'>
                <Toolbar>
                    <Avatar alt="Logo" src={appLogo} className={classes.icon}/>
                    {
                        isSmall ?
                            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                                <RouterLink to="/" style={{
                                    color: themeColor,
                                    textDecoration: 'none'
                                }}>{APP_NAME_SHORT}</RouterLink>
                            </Typography>
                            :
                            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                                <RouterLink to="/"
                                            style={{color: themeColor, textDecoration: 'none'}}>{APP_NAME}</RouterLink>
                            </Typography>
                    }
                </Toolbar>
            </AppBar>
            <Box display='flex' justifyContent='center'>
                <Box>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockIcon/>
                        </Avatar>
                        <Typography component="h1">
                            Sign up
                        </Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={onSubmit}
                        >{
                            (formState) => {
                                return <Form className={classes.form} autoComplete='off'>
                                    <XTextInput
                                        type="text"
                                        name="fullName"
                                        label="Full Name"
                                        autoComplete="fullName"
                                        autoFocus
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <XTextInput
                                        type="email"
                                        name="email"
                                        label="Email"
                                        autoComplete="email"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <XTextInput
                                        type="tel"
                                        name="phone"
                                        label="Phone Number (registered with mobile money)"
                                        variant="outlined"
                                    />
                                    <XTextInput
                                        type="tel"
                                        name="otherPhone"
                                        label="Secondary Phone Number"
                                        variant="outlined"
                                    />
                                    <XTextInput
                                        type="password"
                                        name="password"
                                        label="Password"
                                        autoComplete="off"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <XTextInput
                                        type="password"
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        autoComplete="confirmPassword"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <XTextInput
                                        type="text"
                                        name="companyId"
                                        label="Company Name"
                                        autoComplete="companyId"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <Grid spacing={1} container>
                                        <Grid item xs={5}>
                                            <XSelectInput
                                                name="idCategory"
                                                label="ID Type"
                                                options={toOptions(subIdCategories)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <XTextInput
                                                type="text"
                                                name="idNumber"
                                                label="ID Number"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        !signature && (
                                            <Box width="100%" py={2} display='flex' px={1}>
                                                <Box flexGrow={1}>
                                                    <Button
                                                        type="button"
                                                        fullWidth
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={handleStartSignature}
                                                    >
                                                        Upload Signature
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )
                                    }

                                    <Box px={inputPaddingX}>
                                        <FormControlLabel
                                            value="end"
                                            checked={agreement}
                                            onChange={onAgreementChanged}
                                            disabled={formState.isSubmitting}
                                            control={<Checkbox color="primary"/>}
                                            label={<Typography style={{color: 'black'}}>
                                                I have read and agree to the
                                                &nbsp;<Link href={termsLink} target='_blank' to=''><b>terms and
                                                conditions</b></Link>&nbsp;
                                                of the {APP_NAME_SHORT} Customer Agreement
                                            </Typography>}
                                            labelPlacement="end"
                                        />
                                        {
                                            agreement ? <></> :
                                                <Typography variant="subtitle2" className={classes.invalid}>
                                                    Accept terms and conditions
                                                </Typography>
                                        }

                                    </Box>
                                    <Box width="100%" py={2} display='flex' px={1}>
                                        <Box flexGrow={1}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                disabled={formState.isSubmitting}
                                            >
                                                Sign up
                                            </Button>
                                        </Box>
                                    </Box>

                                </Form>
                            }
                        }
                        </Formik>

                        <Box display="flex" justifyContent='center' pt={2}>
                            <Box>
                                <Link to={localRoutes.login}>Login</Link>
                            </Box>
                        </Box>
                        <SignatureUpload
                            show={signatureUpload}
                            onClose={() => setSignatureUpload(false)}
                            onDone={handleSignatureComplete}
                        />
                    </Paper>
                </Box>
            </Box>
        </div>
    );
}

export default Register
