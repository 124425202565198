/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountManagerUpdateModel {
  /** @format uuid */
  organizationId?: string;

  /** @format uuid */
  managerId?: string;
}

export interface ActivateAccountRequest {
  token: string;
  userId: string;
}

export enum AddressCategory {
  Other = "Other",
  Home = "Home",
  Work = "Work",
}

export interface AddressViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: AddressCategory;

  /** @format uuid */
  contactId?: string;
  country?: string | null;
  district?: string | null;
  county?: string | null;
  subCounty?: string | null;
  village?: string | null;
  parish?: string | null;
  postalCode?: string | null;
  street?: string | null;
  freeForm?: string | null;
  placeId?: string | null;
  latLon?: string | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format date-time */
  endDate?: string | null;
  isPrimary?: boolean;
}

export interface ApproveInvoiceWithReceiptModel {
  /** @format uuid */
  id?: string;
  approved?: boolean;
  remarks?: string | null;
}

export interface BankAccountViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  bank?: string | null;
  branch?: string | null;
  name?: string | null;
  number?: string | null;

  /** @format uuid */
  contactId?: string;
}

export interface BaseResponse {
  message?: string | null;
  status?: boolean;
  data?: Record<string, any>;
}

export enum BulkNotificationCategory {
  Sms = "Sms",
  Email = "Email",
}

export enum BulkNotificationStatus {
  Created = "Created",
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
}

export interface BulkNotificationViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;

  /** @format date-time */
  lastUpdated?: string | null;
  isDeleted?: boolean;
  sendOption?: SendOption;
  status?: BulkNotificationStatus;
  category?: BulkNotificationCategory;

  /** @format date-time */
  timeToSend?: string | null;
  description?: string | null;

  /** @format uuid */
  templateId?: string | null;
  template?: ComboModel;

  /** @format int32 */
  numberOfMessages?: number;

  /** @format int32 */
  completedMessages?: number;
  templateText?: string | null;
  referenceId?: string | null;
  vendorReference?: string | null;

  /** @format date-time */
  startedAt?: string | null;

  /** @format date-time */
  completedAt?: string | null;
  sender?: ContactMiniViewModel;

  /** @format uuid */
  senderId?: string | null;
}

export interface BulkRecipientModel {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  contactId?: string | null;
  fullName?: string | null;
  phoneNumbers?: string[] | null;
  metadata?: Record<string, string>;
}

export interface BulkSmsCreateModel {
  description: string;
  body: string;

  /** @format uuid */
  sender?: string | null;
  recipients: BulkRecipientModel[];

  /** @format uuid */
  template?: string | null;
  sendOption?: SendOption;

  /** @format date-time */
  timeToSend?: string | null;
}

export enum CivilStatus {
  Other = "Other",
  Single = "Single",
  Married = "Married",
  Divorced = "Divorced",
}

export interface ComboModel {
  id?: string | null;
  name?: string | null;
}

export interface CompanyAdminEditModel {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  contactPersonId?: string;

  /** @format uuid */
  responsibleContactId?: string;
}

export enum CompanyCategory {
  Limited = "Limited",
  Ngo = "Ngo",
  Other = "Other",
}

export interface CompanyCreateModel {
  /** @format uuid */
  id?: string;
  companyId: string;
  email?: string | null;
  phone: string;
  category: CompanyCategory;
  name: string;
  tinNumber: string;

  /** @format date-time */
  dateOfIncorporation?: string | null;

  /** @format int32 */
  numberOfEmployees: number;

  /** @format int32 */
  invoicingDay: number;

  /** @format uuid */
  contactPersonId?: string | null;

  /** @format uuid */
  responsibleContactId?: string | null;
}

export interface CompanyViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  name?: string | null;
  companyId?: string | null;
  category?: CompanyCategory;

  /** @format int32 */
  numberOfEmployees?: number;

  /** @format double */
  interestRate?: number | null;

  /** @format double */
  maximumSalaryPercentage?: number | null;

  /** @format int32 */
  invoicingDay?: number;

  /** @format date-time */
  dateOfIncorporation?: string | null;

  /** @format uuid */
  contactId?: string;

  /** @format uuid */
  contactPersonId?: string | null;
  contactPerson?: ContactMiniViewModel;

  /** @format uuid */
  responsibleContactId?: string | null;
  responsibleContact?: ContactMiniViewModel;
}

export interface ContactActivateModel {
  /** @format uuid */
  id?: string;
  isActive?: boolean;
  reason?: string | null;
}

export interface ContactAdminViewModel {
  /** @format uuid */
  organizationId?: string | null;
  organization?: ContactMiniViewModel;

  /** @format uuid */
  contactPersonId?: string | null;
  contactPerson?: ContactMiniViewModel;

  /** @format uuid */
  responsibleContactId?: string | null;
  responsibleContact?: ContactMiniViewModel;
}

export enum ContactCategory {
  Person = "Person",
  Company = "Company",
}

export interface ContactMiniViewModel {
  /** @format uuid */
  id?: string;
  name?: string | null;
}

export interface ContactOrgEditModel {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  organizationId?: string | null;
}

export interface ContactTagViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;

  /** @format uuid */
  contactId?: string;
  value?: string | null;
}

export enum ContactUrlCategory {
  Website = "Website",
  Facebook = "Facebook",
  Linkedin = "Linkedin",
  Twitter = "Twitter",
}

export interface ContactUrlViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;

  /** @format uuid */
  contactId?: string;
  category?: ContactUrlCategory;
  value?: string | null;
}

export interface ContactViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: ContactCategory;
  person?: PersonViewModel;
  company?: CompanyViewModel;
  isActive?: boolean;
  isAccountManager?: boolean;

  /** @format date-time */
  isActivatedOn?: string;

  /** @format uuid */
  isActivatedById?: string | null;
  isActivatedBy?: ContactMiniViewModel;

  /** @format uuid */
  organizationId?: string | null;
  organization?: ContactMiniViewModel;
  occasions?: OccasionViewModel[] | null;
  identifications?: IdentificationViewModel[] | null;
  phones?: PhoneViewModel[] | null;
  emails?: EmailViewModel[] | null;
  addresses?: AddressViewModel[] | null;
  urls?: ContactUrlViewModel[] | null;
  tags?: ContactTagViewModel[] | null;
  bankAccounts?: BankAccountViewModel[] | null;
  nextOfKins?: NextOfKinViewModel[] | null;
}

export interface ContactViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  totalPages?: number;

  /** @format int32 */
  total?: number;
  data?: ContactViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface DirectLoanRequest {
  /** @format date-time */
  applicationDate?: string;
  category: LoanRequestCategory;
  username: string;
  password: string;
  sessionId: string;

  /** @format double */
  amount: number;
  failureSMS?: boolean | null;
}

export interface DirectLoanResponse {
  /** @format date-time */
  createdAt?: string;
  referenceNumber?: string | null;
  status?: LoanStatus;
  subStatus?: LoanSubStatus;

  /** @format double */
  totalAmount?: number;

  /** @format double */
  principleAmount?: number;

  /** @format double */
  interestAmount?: number;

  /** @format date-time */
  dueDate?: string;
}

export interface EmailAttachment {
  fileName?: string | null;

  /** @format byte */
  bytes?: string | null;
}

export enum EmailCategory {
  Work = "Work",
  Personal = "Personal",
  Other = "Other",
}

/**
 * Email message details
 */
export interface EmailMessage {
  /** From */
  from?: string | null;

  /** To */
  to?: string[] | null;

  /** BCC */
  bcc?: string[] | null;

  /** CC */
  cc?: string[] | null;

  /** Body */
  body: string;

  /** Sender name */
  senderName?: string | null;

  /** Subject */
  subject: string;

  /** Is Html Body */
  isBodyHtml?: boolean;

  /** Attachments */
  attachments?: EmailAttachment[] | null;
}

export interface EmailNotification {
  /** @format date-time */
  sentAt?: string | null;
  senderName?: string | null;
  subject?: string | null;
  body?: string | null;
  to?: string | null;
  isHtml?: boolean;
  from?: string | null;

  /** CSV of emails */
  bcc?: string | null;
  cc?: string | null;
  attachments?: string | null;
}

export interface EmailViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: EmailCategory;

  /** @format uuid */
  contactId?: string;
  value?: string | null;
  isPrimary?: boolean;
}

export interface ForgotPasswordRequest {
  /** @format email */
  email: string;
}

export enum Gender {
  Female = "Female",
  Male = "Male",
}

export interface GenerateInvoiceModel {
  onlyExpired?: boolean;

  /** @format uuid */
  organizationId?: string | null;
}

export interface GenerateInvoiceResponseModel {
  status?: boolean;
  message?: string | null;
}

export enum IdentificationCategory {
  NIN = "NIN",
  NSSF = "NSSF",
  TIN = "TIN",
  FCN = "FCN",
  Passport = "Passport",
  DrivingPermit = "DrivingPermit",
  VillageCard = "VillageCard",
  Other = "Other",
}

export interface IdentificationViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: IdentificationCategory;

  /** @format uuid */
  contactId?: string;
  value?: string | null;
  issuingCountry?: string | null;

  /** @format date-time */
  issueDate?: string;

  /** @format date-time */
  expiryDate?: string;
  isPrimary?: boolean;
}

export interface ImportError {
  /** @format int32 */
  row?: number;

  /** @format int32 */
  col?: number;
  error?: string | null;
}

export interface InvoiceCollectionViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: PaymentCategory;
  status?: PaymentStatus;

  /** @format date-time */
  transactionDate?: string;
  account?: string | null;

  /** @format double */
  amount?: number;
  vendor?: PaymentVendor;
  vendorTransactionId?: string | null;
  vendorStatus?: string | null;
  message?: string | null;
  rawData?: string | null;

  /** @format uuid */
  invoiceId?: string;
}

export interface InvoiceLoanModel {
  /** @format date-time */
  createdAt?: string;

  /** @format uuid */
  loanId?: string;

  /** @format uuid */
  applicantId?: string;
  applicantName?: string | null;
  repayments?: RepaymentViewModel[] | null;

  /** @format double */
  totalAmount?: number;
}

export interface InvoicePayBackRequest {
  /** @format uuid */
  invoiceId?: string;
  phone: string;

  /** @format double */
  amount?: number | null;
}

export enum InvoiceStatus {
  Generated = "Generated",
  Posted = "Posted",
  Paid = "Paid",
  AwaitingApproval = "AwaitingApproval",
  AwaitingConfirmation = "AwaitingConfirmation",
  Void = "Void",
  Overdue = "Overdue",
  PartiallyPaid = "PartiallyPaid",
}

export interface InvoiceViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  status?: InvoiceStatus;
  comments?: string | null;

  /** @format int32 */
  invoiceNumber?: number;
  contactCategory?: ContactCategory;

  /** @format uuid */
  contactId?: string;
  contact?: ContactMiniViewModel;

  /** @format uuid */
  collectionId?: string;
  collection?: InvoiceCollectionViewModel;

  /** @format double */
  amount?: number;

  /** @format date-time */
  issueDate?: string;

  /** @format date-time */
  dueDate?: string;

  /** @format date-time */
  clearedAt?: string | null;

  /** @format uuid */
  clearedBy?: string | null;
  clearedByData?: ComboModel | null;

  /** @format uuid */
  documentId?: string | null;
  document?: XDocumentViewModel;
  clearedLoans?: string[] | null;

  /** @format double */
  amountPaid?: number | null;

  /** @format uuid */
  approvedByData?: ComboModel | null;
  approvedBy?: string | null;
  approved?: boolean | null;

  /** @format date-time */
  approvedOn?: string | null;
  approverRemarks?: string | null;

  /** @format uuid */
  approvedBy1Data?: ComboModel | null;
  approvedBy1?: string | null;
  approved1?: boolean | null;

  /** @format date-time */
  approvedOn1?: string | null;
  approverRemarks1?: string | null;
  loans?: InvoiceLoanModel[] | null;
}

export interface LoanAgingModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  applicationDate?: string;

  /** @format int32 */
  loanNumber?: number;

  /** @format uuid */
  organizationId?: string;
  organization?: ContactMiniViewModel;
  applicant?: ContactMiniViewModel;

  /** @format uuid */
  applicantId?: string;

  /** @format date-time */
  releaseDate?: string | null;

  /** @format double */
  amount?: number;

  /** @format double */
  interestAmount?: number;

  /** @format double */
  penalty?: number;

  /** @format int32 */
  daysInAreas?: number;

  /** @format int32 */
  ageInDays?: number;
  category?: string | null;
}

export interface LoanAgingSummaryModel {
  /** @format double */
  totalLoans?: number | null;

  /** @format int64 */
  totalLoansCount?: number | null;

  /** @format double */
  dueIn7Days?: number | null;

  /** @format int64 */
  dueIn7DaysCount?: number | null;

  /** @format double */
  dueIn30Days?: number | null;

  /** @format int32 */
  dueIn30DaysCount?: number | null;

  /** @format double */
  overdue?: number | null;

  /** @format int64 */
  overdueCount?: number | null;
  loanData?: LoanAgingModel[] | null;
}

export interface LoanBalanceViewModel {
  /** @format double */
  principle?: number;

  /** @format double */
  totalAmount?: number;

  /** @format double */
  interest?: number;

  /** @format double */
  interestRate?: number;
  message?: string | null;
  defaultPhone?: string | null;
  invoices?: InvoiceViewModel[] | null;
  pendingLoans?: LoanListViewModel[] | null;
}

export enum LoanCategory {
  Salary = "Salary",
}

export enum LoanDecision {
  Pending = "Pending",
  Approved = "Approved",
  Declined = "Declined",
  Manual = "Manual",
}

export interface LoanDetailedViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;

  /** @format int32 */
  loanNumber?: number;

  /** @format date-time */
  lastAccessed?: string;
  category?: LoanCategory;
  referenceNumber?: string | null;
  status?: LoanStatus;
  subStatus?: LoanSubStatus;
  subStatusLog?: string | null;

  /** @format double */
  amount?: number;

  /** @format int32 */
  durationInMonths?: number;

  /** @format date-time */
  applicationDate?: string;

  /** @format double */
  administrationFee?: number;

  /** @format double */
  inceptionFee?: number;

  /** @format double */
  interestRate?: number;

  /** @format uuid */
  organizationId?: string;
  organization?: ContactMiniViewModel;
  agent?: ContactMiniViewModel;

  /** @format uuid */
  agentId?: string | null;
  applicant?: ContactViewModel;

  /** @format uuid */
  applicantId?: string;
  assignee?: ContactMiniViewModel;

  /** @format uuid */
  assigneeId?: string | null;
  repaymentCycle?: RepaymentCycle;
  score?: LoanScoreViewModel;
  disbursement?: LoanDisbursementViewModel;
  repaymentPlan?: RepaymentViewModel[] | null;

  /** @format date-time */
  closedOn?: string | null;

  /** @format uuid */
  closedById?: string | null;
  closedBy?: ContactMiniViewModel;
  requestData?: string | null;
  documents?: LoanDocumentViewModel[] | null;
}

export interface LoanDisbursementViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: PaymentCategory;
  status?: PaymentStatus;

  /** @format date-time */
  transactionDate?: string;
  account?: string | null;

  /** @format double */
  amount?: number;
  vendor?: PaymentVendor;
  vendorTransactionId?: string | null;
  vendorStatus?: string | null;
  message?: string | null;
  rawData?: string | null;

  /** @format uuid */
  loanId?: string;
}

export enum LoanDocumentCategory {
  Contract = "Contract",
  Identification = "Identification",
  Paycheck = "Paycheck",
  TaxClearance = "TaxClearance",
}

export interface LoanDocumentViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: LoanDocumentCategory;
  name?: string | null;
  fileName?: string | null;
  contentType?: string | null;

  /** @format date-time */
  dateVerified?: string | null;

  /** @format uuid */
  verifierId?: string | null;
  verifier?: ContactMiniViewModel;

  /** @format uuid */
  loanId?: string;
}

export interface LoanListViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: LoanCategory;

  /** @format int32 */
  loanNumber?: number;
  referenceNumber?: string | null;
  status?: LoanStatus;
  subStatus?: LoanSubStatus;

  /** @format double */
  amount?: number;

  /** @format int32 */
  durationInMonths?: number;

  /** @format date-time */
  applicationDate?: string;

  /** @format double */
  administrationFee?: number;

  /** @format double */
  inceptionFee?: number;

  /** @format double */
  interestRate?: number;

  /** @format double */
  interestAmount?: number;
  requestData?: string | null;
  organization?: ContactMiniViewModel;

  /** @format uuid */
  organizationId?: string;
  agent?: ContactMiniViewModel;

  /** @format uuid */
  agentId?: string | null;
  applicant?: ContactMiniViewModel;

  /** @format uuid */
  applicantId?: string;
  assignee?: ContactMiniViewModel;

  /** @format uuid */
  assigneeId?: string | null;
  repaymentCycle?: RepaymentCycle;
  disbursementCategory?: PaymentCategory;

  /** @format date-time */
  releaseDate?: string | null;
}

export interface LoanListViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  totalPages?: number;

  /** @format int32 */
  total?: number;
  data?: LoanListViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface LoanOriginationRequest {
  /** @format date-time */
  applicationDate?: string;
  category: LoanRequestCategory;
  phone: string;
  sessionId: string;

  /** @format double */
  amount: number;
  network: string;
  shortCode: string;
}

export interface LoanReportEntry {
  key?: string | null;
  value?: string | null;
}

export enum LoanRequestCategory {
  Other = "Other",
  MobileApp = "MobileApp",
  Ussd = "Ussd",
  WebApp = "WebApp",
}

export interface LoanScoreViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;

  /** @format double */
  offerAmount?: number;
  decision?: LoanDecision;
  model?: string | null;
  remarks?: string | null;

  /** @format date-time */
  runDate?: string;

  /** @format uuid */
  loanId?: string;
}

export interface LoanStatsViewModel {
  /** @format double */
  totalIssued?: number;

  /** @format double */
  totalRecovered?: number;

  /** @format double */
  totalUnpaid?: number;

  /** @format double */
  totalOverdue?: number;
}

export enum LoanStatus {
  Open = "Open",
  Closed = "Closed",
  Error = "Error",
}

export enum LoanSubStatus {
  Created = "Created",
  PendingPayout = "PendingPayout",
  PaidOut = "PaidOut",
  PayoutFailure = "PayoutFailure",
  RepaymentFailed = "RepaymentFailed",
  Recovered = "Recovered",
  BadLoan = "BadLoan",
}

export interface LoginRequest {
  username: string;

  /** @format password */
  password: string;
  rememberMe?: boolean;
}

export interface NextOfKinCreateModel {
  firstName: string;
  lastName: string;
  middleName?: string | null;
  email: string;
  phone: string;
  relationship: string;

  /** @format uuid */
  contactId?: string;
}

export interface NextOfKinEditModel {
  /** @format uuid */
  id?: string;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  email: string;
  phone: string;
  relationship: string;

  /** @format uuid */
  contactId?: string;
}

export interface NextOfKinViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  email?: string | null;
  phone?: string | null;
  relationship?: string | null;

  /** @format uuid */
  contactId?: string;
}

export interface NotFoundResult {
  /** @format int32 */
  statusCode?: number;
}

export enum NotificationCategory {
  Email = "Email",
  Sms = "Sms",
  Both = "Both",
}

export enum NotificationStatus {
  Created = "Created",
  ReadyToSend = "ReadyToSend",
  Sending = "Sending",
  Failed = "Failed",
  FailedPermanently = "FailedPermanently",
  Sent = "Sent",
}

export interface NotificationViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: NotificationCategory;
  sendOption?: SendOption;
  status?: NotificationStatus;

  /** @format date-time */
  timeToSend?: string | null;
  referenceId?: string | null;
  vendorReference?: string | null;

  /** @format date-time */
  processedAt?: string | null;

  /** @format date-time */
  deliveredAt?: string | null;

  /** @format int32 */
  sendAttempts?: number;

  /** @format int32 */
  maxSendAttempts?: number | null;
  sender?: ContactMiniViewModel;

  /** @format uuid */
  senderId?: string | null;
  sms?: SmsNotification;
  email?: EmailNotification;
}

export enum OccasionCategory {
  Birthday = "Birthday",
  Anniversary = "Anniversary",
  Engaged = "Engaged",
  Other = "Other",
}

export interface OccasionViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  details?: string | null;
  category?: OccasionCategory;

  /** @format date-time */
  value?: string;

  /** @format uuid */
  contactId?: string;
}

export interface OkResult {
  /** @format int32 */
  statusCode?: number;
}

export enum PaymentCategory {
  Cash = "Cash",
  Cheque = "Cheque",
  MobileMoney = "MobileMoney",
  WireTransfer = "WireTransfer",
}

export enum PaymentStatus {
  Pending = "Pending",
  Sent = "Sent",
  Success = "Success",
  Failed = "Failed",
}

export enum PaymentVendor {
  IotecPay = "IotecPay",
  Airtel = "Airtel",
}

export interface PersonCreateModel {
  firstName: string;
  lastName: string;
  middleName?: string | null;
  gender: Gender;

  /** @format date-time */
  dateOfBirth: string;
  civilStatus?: CivilStatus;
  salutation?: Salutation;
  companyName?: string | null;
  tags?: string[] | null;
  email: string;
  phone: string;
  otherPhone?: string | null;
  idCategory: IdentificationCategory;
  idNumber: string;

  /** @format date-time */
  idIssueDate?: string | null;

  /** @format date-time */
  idExpiryDate: string;

  /** @format double */
  monthlyNetSalary: number;

  /** @format date-time */
  dateOfEmployment: string;

  /** @format uuid */
  organizationId?: string | null;

  /** @format uuid */
  contactPersonId?: string | null;

  /** @format uuid */
  responsibleContactId?: string | null;
}

export interface PersonViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;

  /** @format uuid */
  contactId?: string;
  salutation?: Salutation;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: Gender;
  civilStatus?: CivilStatus;

  /** @format date-time */
  dateOfBirth?: string | null;

  /** @format double */
  monthlyNetSalary?: number;

  /** @format double */
  monthlyGrossSalary?: number;

  /** @format date-time */
  dateOfEmployment?: string;
  fullName?: string | null;
}

export enum PhoneCategory {
  Mobile = "Mobile",
  Office = "Office",
  Home = "Home",
  Fax = "Fax",
  Other = "Other",
}

export interface PhoneLoginViewModel {
  phone: string;

  /** @format password */
  password: string;
}

export interface PhoneViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  category?: PhoneCategory;

  /** @format uuid */
  contactId?: string;
  value?: string | null;
  isPrimary?: boolean;
}

export interface PublicLoanConfig {
  /** @format double */
  stepAmount?: number;

  /** @format double */
  maxAmount?: number;

  /** @format double */
  minAmount?: number;

  /** @format double */
  interestRate?: number;
}

export interface RecoverLoanModel {
  /** @format uuid */
  loanId: string;

  /** @format uuid */
  officerId?: string;
}

export interface RegisterModel {
  fullName: string;

  /** @format date-time */
  dateOfBirth?: string | null;
  gender?: Gender;

  /** @format email */
  email: string;

  /** @format tel */
  phone: string;
  otherPhone?: string | null;

  /** @format password */
  password: string;

  /** @format password */
  confirmPassword?: string | null;
  companyId: string;
  idCategory: IdentificationCategory;
  idNumber: string;
  signatureId: string;

  /** @format date-time */
  idExpiryDate?: string | null;
  acceptTerms?: boolean;
}

export enum RepaymentCycle {
  BiMonthly = "BiMonthly",
  BiWeekly = "BiWeekly",
  Daily = "Daily",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Weekly = "Weekly",
  Yearly = "Yearly",
}

export enum RepaymentStatus {
  Pending = "Pending",
  Paid = "Paid",
  PartiallyPaid = "PartiallyPaid",
}

export interface RepaymentViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  status?: RepaymentStatus;

  /** @format double */
  totalAmount?: number;

  /** @format double */
  principleAmount?: number;

  /** @format double */
  interestAmount?: number;

  /** @format date-time */
  dueDate?: string;
  remarks?: string | null;

  /** @format double */
  amountPaid?: number | null;

  /** @format date-time */
  repaidOn?: string | null;

  /** @format date-time */
  approvedOn?: string | null;

  /** @format uuid */
  approvedBy?: string | null;

  /** @format uuid */
  loanId?: string;
  applicant?: ComboModel;
}

export enum ReportPeriod {
  Monthly = "Monthly",
  Weekly = "Weekly",
  Daily = "Daily",
  Yearly = "Yearly",
}

export interface ResetPasswordRequest {
  /** @format password */
  oldPassword: string;

  /** @format password */
  newPassword: string;

  /** @format password */
  confirmNewPassword: string;
}

export enum Salutation {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
  Dr = "Dr",
  Prof = "Prof",
  Other = "Other",
}

export interface SendEmailModel {
  /**
   * Reference
   * @format uuid
   */
  referenceId?: string | null;
  sendOption?: SendOption;

  /** @format date-time */
  timeToSend?: string | null;
  retryIfSendFails?: boolean;

  /** Email message details */
  message: EmailMessage;
}

export enum SendOption {
  Default = "Default",
  AddToQueue = "AddToQueue",
  SendNowAndWait = "SendNowAndWait",
  SendNowAndDoNotWait = "SendNowAndDoNotWait",
}

export interface SendSmsModel {
  msisdn?: string | null;
  text?: string | null;
  referenceId?: string | null;
}

export interface SetNewPasswordRequest {
  token: string;
  userId: string;

  /** @format password */
  newPassword: string;

  /** @format password */
  confirmNewPassword: string;
}

export interface SingleSmsCreateModel {
  body: string;

  /** @format uuid */
  sender?: string | null;

  /** @format uuid */
  recipient?: string | null;

  /** @format uuid */
  template?: string | null;
  phoneNumbers: string[];
}

export interface SmsBulkImportResponse {
  status?: boolean;
  message?: string | null;
  errors?: ImportError[] | null;
  data?: BulkRecipientModel[] | null;
}

export interface SmsNotification {
  /** @format date-time */
  sentAt?: string | null;
  msisdn?: string | null;

  /** @format uuid */
  recipient?: string | null;
  recipientName?: string | null;
  text?: string | null;
}

export interface TemplateCreateModel {
  name: string;
  body: string;
  details?: string | null;
}

export interface TemplateEditModel {
  /** @format uuid */
  id?: string;
  body?: string | null;
  name?: string | null;
  details?: string | null;
}

export interface TemplateTagCreateModel {
  name?: string | null;
  details?: string | null;
}

export interface TemplateTagEditModel {
  /** @format uuid */
  id?: string;
  name?: string | null;
  details?: string | null;
}

export interface TemplateTagViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  name?: string | null;
  details?: string | null;
}

export interface TemplateViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  body?: string | null;
  name?: string | null;
  details?: string | null;

  /** @format uuid */
  creatorId?: string;
  creator?: ContactMiniViewModel;
}

export interface TriggerPaymentModel {
  /** @format uuid */
  loanId?: string;
  category?: PaymentCategory;
}

export interface UnauthorizedResult {
  /** @format int32 */
  statusCode?: number;
}

export interface UpdateInvoiceClearedModel {
  /** @format uuid */
  id?: string;
  clearedLoans?: string[] | null;
}

export interface UpdateUserEmailModel {
  id: string;
  email: string;
}

export interface UserCreateModelV1 {
  /** @format uuid */
  contactId: string;
  roles: string[];
}

export interface UserUpdateModel {
  id: string;
  roles: string[];
}

export interface UssdRegisterModel {
  fullName: string;
  email?: string | null;

  /** @format tel */
  phone: string;
  companyName: string;
}

export interface XDocumentViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  name?: string | null;
  contentType?: string | null;
  originalName?: string | null;
  description?: string | null;
  size?: string | null;

  /** @format uuid */
  organizationId?: string;
}
