import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { useHistory} from 'react-router-dom';
import image from "../../assets/pexels-daniel-putzer-633409.jpg";
import {useSelector} from "react-redux";
import {AppState} from "../../data/types";
import { appLogo, companyRefUrl, localRoutes} from "../../data/constants";
import {isBackOfficeUser} from "../../data/appRoles";
import {Profile} from "../../layout/Profile";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import {Copyright} from "./Copyright";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {themeColor} from "../../theme/custom-colors";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    logo: {
        height: 40,
        width: 'auto',
    } ,
    root: {
        color: 'white',
        height:"100%",
        display:'flex',
        flexDirection:'column'
    },
    heroContent: {
        width:'100%',
        marginTop: theme.spacing(2),
        backgroundColor:themeColor,
        [theme.breakpoints.down('lg')]: {
            paddingTop: theme.spacing(2),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        color: 'white',
        alignSelf: 'end'
    },
    heroText: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: theme.spacing(8),
        },
        paddingTop: theme.spacing(10),
        color: 'white',
    },
    mainContent: {
        height:'calc(100% - 64px)',
        display:'flex',
        flexDirection:'column'
    },
    heroButtons: {
        marginTop: theme.spacing(1),
    },
    footer: {
        padding: theme.spacing(1),
    },
    link: {
        margin: theme.spacing(1, 1.5),
        cursor: 'pointer'
    },
    toolbarTitle: {
        flexGrow: 1,
        paddingTop: theme.spacing(1),
    },
    main: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
        },
        paddingTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center'
    }
}));


const Home:React.FC<any> = ({children}) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const user: any = useSelector((state: AppState) => state.core.user);
    const history = useHistory();

    function handleAdminConsole(e: any) {
        e.preventDefault()
        history.push(localRoutes.dashboard)
    }
    return (
        <div
            style={
                isSmall ?
                    {
                        backgroundPosition: "top center"
                    }
                    :
                    {
                        backgroundImage: "url(" + image + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "top center"
                    }
            }
            className={classes.root}
        >
            <AppBar position="relative" color='transparent'>
                <Toolbar>
                    <div className={classes.toolbarTitle}>
                        <img src={appLogo} alt="logo" className={classes.logo} style={{cursor:'pointer'}}/>
                    </div>
                    <nav>
                        <HiddenJs smDown>
                            {
                                user && isBackOfficeUser(user) &&
                                <Link
                                    variant="button"
                                    color="inherit" href="#"
                                    className={classes.link}
                                    onClick={handleAdminConsole}
                                >
                                    Admin Console
                                </Link>
                            }
                        </HiddenJs>
                        <Profile/>
                    </nav>
                </Toolbar>
            </AppBar>
            <div className={classes.mainContent}>
                <Container style={{flexGrow:1}}>
                    <Grid container spacing={3}>
                        <HiddenJs smDown>
                            <Grid item md={6} lg={5} sm={12} className={classes.heroText}>
                                <Typography component="div" variant="h1" align="center" color="inherit" gutterBottom>
                                    Need<br/> extra<br/> money?
                                </Typography>
                            </Grid>
                        </HiddenJs>
                        <Grid item md={6} lg={7} sm={12} xs={12}>
                            <div className={classes.main}>
                                {
                                    children
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                {
                    isSmall ?
                        <div className={classes.footer}>
                            <Copyright/>
                        </div>
                        :
                        <div className={classes.heroContent}>
                            <Container maxWidth="md">
                                <Typography component="h1" variant="h5" align="center" color="inherit" gutterBottom>
                                    About Us
                                </Typography>
                                <Typography variant="subtitle1" align="center" color="inherit" paragraph>
                                    ioTec  Limited  is  a  business-to-business  financial  technology  company  licensed&nbsp;
                                    and regulated by the Central Bank Of Uganda to build secure financial technology&nbsp;
                                    solutions that bring services closer to individuals and businesses through intuitive&nbsp;
                                    digital channels.&nbsp;
                                    <Link href={companyRefUrl} color='inherit' target='_blank' style={{textDecoration:'underline'}}>Learn more...</Link>
                                </Typography>
                            </Container>
                            <div className={classes.footer}>
                                <Copyright/>
                            </div>
                        </div>

                }

            </div>
        </div>
    );
}


export default Home;
